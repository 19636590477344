exports.components = {
  "component---src-pages-404-html-js": () => import("./../../../src/pages/404.html.js" /* webpackChunkName: "component---src-pages-404-html-js" */),
  "component---src-pages-admin-html-js": () => import("./../../../src/pages/admin.html.js" /* webpackChunkName: "component---src-pages-admin-html-js" */),
  "component---src-pages-demoedittable-html-js": () => import("./../../../src/pages/demoedittable.html.js" /* webpackChunkName: "component---src-pages-demoedittable-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-html-js": () => import("./../../../src/pages/login.html.js" /* webpackChunkName: "component---src-pages-login-html-js" */),
  "component---src-pages-mobile-trang-chu-html-js": () => import("./../../../src/pages/mobile/trang-chu.html.js" /* webpackChunkName: "component---src-pages-mobile-trang-chu-html-js" */),
  "component---src-pages-receive-html-js": () => import("./../../../src/pages/receive.html.js" /* webpackChunkName: "component---src-pages-receive-html-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

