/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import {globalHistory} from '@reach/router';
import { NotificationProvider } from './src/context/AddItemNotificationProvider';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
export const wrapRootElement = ({ element }) => (
  <NotificationProvider>{element}</NotificationProvider>
);


export const onInitialClientRender = () => {
  window.addEventListener('popstate', () =>
    window.location.href = window.location.href
  );
  globalHistory._onTransitionComplete();
}